


















































































































import Ueditor from "@/components/Ueditor/index.vue";
import { Component, Ref, Vue } from "vue-property-decorator";
import api from "@/api";
import {
  ApplicationPaymentCreateOrUpdateDto,
  ApplicationPaymentDto,
  AttachmentHostType,
  DataDictionaryDtoPagedResultDto,
  FundDto,
  FundDtoPagedResultDto,
  FundProjectDto,
  FundProjectDtoPagedResultDto,
  FundProjectType,
  MerchantBankAccountDto,
  MerchantBankAccountDtoPagedResultDto,
  MerchantDtoPagedResultDto,
} from "@/api/appService";
import { ElForm } from "element-ui/types/form";
import AbSelect from "@/components/AbSelect/index.vue";
import MultipleUploadFile from "@/components/MultipleUploadFile/index.vue";
import SimpleUploadImage from "@/components/SimpleUploadImage/index.vue";

@Component({
  components: {
    AbSelect,
    Ueditor,
    MultipleUploadFile,
    SimpleUploadImage,
  },
})
export default class CreateApplicationPayment extends Vue {
  @Ref() readonly dataForm!: ElForm;
  dataId?: number = 0;
  form: ApplicationPaymentCreateOrUpdateDto = {
    id: 0,
    fundId: undefined,
    payMoney: 0,
    fundProjectId: undefined,
    merchantBankAccountId: undefined,
    merchantId: undefined,
  };
  moneyBig = "零元";

  applyTime = "零元";
  typeList: any = [];
  fundList: FundDto[] = [];
  projectList: FundProjectDto[] = [];
  projectCategory: any = []; // 项目类型
  projectDomain: any = []; // 项目领域
  activityClass: any = []; // 活动分类
  btnDisabled = false;
  approvalCategory: any = []; // 审批类别
  merchant: any = []; // 客商账户
  merchantBankAccount: any = []; // 客商银行账户
  bankAccountCode = "";

  get hostTypeFile() {
    return AttachmentHostType.ApplicationPayment;
  }

  created() {
    this.getCurrentDate();
    this.fetchEnumType();
    this.fetchDataDictionary();
    this.fetchFundList();
    this.fetchFundProjectList();
    this.fetchMerchantList();

    if (this.$route.params.id) {
      this.dataId = Number(this.$route.params.id);
      api.applicationPayment.get({ id: this.dataId }).then((res) => {
        this.form = { ...res };
        if (this.form.merchantBankAccountId) {
          api.merchantBankAccount
            .get({ id: this.form.merchantBankAccountId })
            .then((res) => {
              this.bankAccountCode = res.bankAccountCode ?? "";
            });
        }
      });
    }
  }

  // 获取客商账户
  async fetchMerchantList() {
    await api.merchant
      .getAll({
        // isSynced: true,
        maxResultCount: 14211,
      })
      .then((res: MerchantDtoPagedResultDto) => {
        this.merchant = res.items ?? [];
      });
  }

  selectAccount() {
    this.fetchMerchantBankAccountList();
  }

  // 获取客商银行商户
  async fetchMerchantBankAccountList() {
    if (this.form.merchantId) {
      await api.merchantBankAccount
        .getAll({
          merchantId: this.form.merchantId,
          // isSynced: true,
          maxResultCount: 14211,
        })
        .then((res: MerchantBankAccountDtoPagedResultDto) => {
          this.merchantBankAccount = res.items ?? [];
        });
    }
  }

  async selectBankAccount(id: number) {
    if (id) {
      await api.merchantBankAccount
        .get({
          id: id,
        })
        .then((res: MerchantBankAccountDto) => {
          this.bankAccountCode = res.bankAccountCode ?? "";
        });
    }
  }

  async fetchFundList() {
    await api.fund
      .getAll({
        maxResultCount: 14211,
      })
      .then((res: FundDtoPagedResultDto) => {
        this.fundList = res.items ?? [];
      });
  }

  selectFund() {
    this.fetchFundProjectList();
  }

  async fetchFundProjectList() {
    await api.fundProject
      .getAll({
        projectType: FundProjectType.OnlineFundraising,
        fundId: this.form.fundId,
        maxResultCount: 14211,
      })
      .then((res: FundProjectDtoPagedResultDto) => {
        this.projectList = res.items ?? [];
        this.projectList.push({
          id: undefined,
          name: "其他",
        });
      });
  }

  async fetchDataDictionary() {
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ApplicationPaymentProjectCategory",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.projectCategory = res.items;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ApplicationPaymentProjectDomain",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.projectDomain = res.items;
      });
    await api.dataDictionary
      .getDataDictionaryListByKey({
        key: "ApplicationPaymentActivityClass",
        maxResultCount: 1000,
      })
      .then((res: DataDictionaryDtoPagedResultDto) => {
        this.activityClass = res.items;
      });
  }

  async fetchEnumType() {
    await api.enumService
      .getValues({
        typeName: "FundAppropriateType",
      })
      .then((res) => {
        this.typeList = res;
      });
    await api.enumService
      .getValues({
        typeName: "ApprovalCategory",
      })
      .then((res) => {
        this.approvalCategory = res;
      });
  }

  getCurrentDate() {
    let nowDate = new Date();
    let date = {
      year: nowDate.getFullYear(),
      month: nowDate.getMonth() + 1,
      date: nowDate.getDate(),
    };
    this.applyTime = date.year + "年" + date.month + "月" + date.date + "日";
  }

  async save() {
    this.btnDisabled = true;
    (this.$refs.dataForm as any).validate(async (valid: boolean) => {
      if (valid) {
        if (!this.form.title) {
          this.$message.error("请填写请款标题！");
          this.btnDisabled = false;
          return;
        }
        if (this.form.payMoney != undefined && this.form.payMoney <= 0) {
          this.$message.error("请款金额必须大于0！");
          this.btnDisabled = false;
          return;
        }
        if (!this.form.payReason) {
          this.$message.error("请填写请款理由！");
          this.btnDisabled = false;
          return;
        }

        if (this.form!.id) {
          await api.applicationPayment
            .update({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.$router.back();
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        } else {
          await api.applicationPayment
            .create({
              body: this.form,
            })
            .then(() => {
              this.$message.success("更新成功");
              this.$router.back();
            })
            .catch(() => {
              this.btnDisabled = false;
            });
        }
      } else {
        this.$message.error("请检查表单！");
        this.btnDisabled = false;
      }
    });
  }

  changeMoneyAmount() {
    this.moneyBig = this.smalltoBIG(this.form.payMoney);
  }

  smalltoBIG(n: any) {
    n = Number(n);
    let fraction = ["角", "分"];
    let digit = ["零", "壹", "贰", "叁", "肆", "伍", "陆", "柒", "捌", "玖"];
    let unit = [
      ["元", "万", "亿"],
      ["", "拾", "佰", "仟"],
    ];
    let head = n < 0 ? "欠" : "";
    n = Math.abs(n);

    let s = "";

    for (let i = 0; i < fraction.length; i++) {
      s += (
        digit[Math.floor(n * 10 * Math.pow(10, i)) % 10] + fraction[i]
      ).replace(/零./, "");
    }
    s = s || "整";
    n = Math.floor(n);

    for (let i = 0; i < unit[0].length && n > 0; i++) {
      let p = "";
      for (let j = 0; j < unit[1].length && n > 0; j++) {
        p = digit[n % 10] + unit[1][j] + p;
        n = Math.floor(n / 10);
      }
      s = p.replace(/(零.)*零$/, "").replace(/^$/, "零") + unit[0][i] + s;
    }
    const result =
      head +
      s
        .replace(/(零.)*零元/, "元")
        .replace(/(零.)+/g, "零")
        .replace(/^整$/, "零元");
    return result;
  }

  cancel() {
    this.$router.back();
  }

  roleRule = {
    payReason: [
      {
        required: true,
        message: "拟办意见必填",
        trigger: "blur",
      },
    ],
    payMoney: [
      {
        required: true,
        message: "请款金额必填",
        trigger: "blur",
      },
    ],
    title: [
      {
        required: true,
        message: "请款标题必填",
        trigger: "blur",
      },
    ],
    approvalCategory: [
      {
        required: true,
        message: "审批类别必填",
        trigger: "blur",
      },
    ],
    merchantId: [
      {
        required: true,
        message: "审批类别必填",
        trigger: "blur",
      },
    ],
    merchantBankAccountId: [
      {
        required: true,
        message: "审批类别必填",
        trigger: "blur",
      },
    ],
  };
}
